import moment from 'moment'

import { PPC_SYNC_STATUS } from '../../constants/account'

const defaultStartDate = moment()
  .subtract(29, 'days')
  .startOf('day')
const defaultEndDate = moment()

export const defaultUnitSoldData = {
  units_shipped: 0,
  promo_rebates: 0,
  units_sold: 0,
  pending_units: 0
}

export const defaultProfitInfoData = {
  cogs: 0,
  gross_margin: 0,
  gross_profit: 0,
  net_margin: 0,
  net_profit: 0,
  net_revenue: 0,
  opex: 0,
  roi: 0,
  sales_tax: 0,
  total_costs: 0
}

export const defaultRevenueSummaryData = {
  total_revenue: 0,
  sales: 0,
  refunds: 0,
  reimbursements: 0,
  promo_rebates: 0,
  other_income: 0,
  other_income_drilldown: []
}

const defaultNetProfitSummaryData = {
  net_profit: 0,
  net_margin: 0,
  net_revenue: 0,
  cogs: 0,
  gross_margin: 0,
  gross_profit: 0,
  opex: 0
}

export const defaultCostData = {
  amazon_seller_account_id: 0,
  product_id: 0,
  order_fees: 0,
  order_fees_drilldown: [],
  other_expenses_drilldown: [],
  regular_units_sold: 0,
  promo_units_sold: 0,
  unit_cost: 0,
  shipping_cost: 0,
  misc_cost: 0,
  reshipping: 0,
  pay_per_click: 0,
  other_expenses: 0,
  total_cost: 0,
  seller_fees: 0,
  seller_fees_drilldown: []
}

export const sharedInitialState = {
  // Date Selection
  showCalendarModal: false,
  dates: {
    dateRangeType: 'last_30_days',
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    comparisonStartDate: null,
    comparisonEndDate: null,
    comparisonType: null
  },

  // Data
  unitsSoldData: { ...defaultUnitSoldData },
  profitInfoData: { ...defaultProfitInfoData },
  costSummaryData: { ...defaultCostData },
  revenueSummaryData: { ...defaultRevenueSummaryData },
  netProfitSummaryData: { ...defaultNetProfitSummaryData },
  revenueSummary: { isLoading: false },
  netProfitSummary: { isLoading: false },
  previousNetProfitSummary: { isLoading: false },
  previousNetProfitSummaryData: { ...defaultNetProfitSummaryData },
  costSummary: { isLoading: false },
  netMargin: { isLoading: false },
  unitsSold: { isLoading: false },
  revenueOverTime: { isLoading: false },
  revenueOverTimeData: [],
  netProfitOverTime: { isLoading: false },
  netProfitOverTimeData: [],
  costOverTime: { isLoading: false },
  costOverTimeData: [],
  ppc: {
    totals: { ppc_campaign_names: [] },
    dataOverTime: [{ day: '', ppc_spend: '', ppc_seven_day_sales: '' }],
    isLoadingTotals: false,
    isLoadingDataOverTime: false,
    completed: false,
    modalOpen: null,
    ppcSyncStatus: PPC_SYNC_STATUS.initial
  }
}

export function buildPendingState(state, action) {
  return {
    ...state,
    [action.payload]: {
      ...state[action.payload],
      isLoading: true,
      completed: false
    }
  }
}

export function buildCompletedState(state, action) {
  return {
    ...state,
    [action.payload]: {
      ...state[action.payload],
      isSearching: false,
      isLoading: false
    }
  }
}

export function buildSetDateRangeState(state, action) {
  return {
    ...state,
    dates: {
      ...state.dates,
      dateRangeType: action.payload.dateRangeType,
      startDate: action.payload.dateRange.startDate,
      endDate: action.payload.dateRange.endDate
    }
  }
}

export function buildSetComparisonDateRangeState(state, action) {
  return {
    ...state,
    dates: {
      ...state.dates,
      comparisonStartDate: action.payload.startDate,
      comparisonEndDate: action.payload.endDate,
      comparisonType: action.payload.comparisonType
    }
  }
}

export function buildDataState(state, action) {
  return {
    ...state,
    [action.payload.key]: action.payload.data
  }
}

export function buildResetDataState(state, action, initialState) {
  return {
    ...state,
    [action.payload]: initialState[action.payload]
  }
}
